@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

body .main-layout-container .right-layout-container {
  background: none;

  .right-layout-content {
    padding: 16px;
    width: 100%;
    min-width: 382px;
  }
}

.main-layout-container {
  display: flex;
  background: none;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .right-layout-container {
    flex: 1;

    .right-layout-content {
      padding: 0px;
    }
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: initial;
  }
}

.footer-mobile-container {
  padding: 0;
}

.base-header-container .base-header-user {
  right: 95px;
}

.header-home-desktop {
  height: 0px;
}

#header-home-desktop {
  z-index: 9999;
}

#header-home-desktop-container nav.navbar {
  padding: 0px;

  .container-fluid {
    position: absolute;
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 100%;
    height: 0px;
    margin: 40px 40px 0 0;
    padding-right: 0px;
  }
}

.header-search-desktop nav.navbar {
  background: transparent;

  .container-fluid {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: center;
  }
}

.navbar-brand {
  top: 2%;
}

.dkt-close-btn {
  z-index: 1000;
  right: 165px !important;
  top: 18px !important
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
  .map-template.main-layout-container {
    overflow-x: hidden;
  }

  .video-background-container {
    position: absolute;
    width: 100%;
    height: 107%;
    overflow: hidden;

    video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (orientation: portrait) {
  // .main-layout-container {
  //   overflow: auto;
  // }

  .map-template.main-layout-container {
    overflow: hidden;
  }

  .video-background-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .navbar-brand {
    top: 10px;
  }
}