@import '../../../config/variables.scss';

.route-details {
  background-color: $card_bg;
}

.route-body-detail-mobile h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $text !important;
  margin-top: 24px;
}

.route-details-sidebar h5 {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: $primary;
  margin-bottom: 8px;
}

.card-iframe-video {
  border: 0;
}

.route-body-detail-mobile p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $text !important;
}

.route-body-detail-mobile .detail-box {
  max-width: 173px;

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 12px;
  }
}

.route-body-detail-mobile {
  .card-pill {
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 25.5px;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #404040;
    margin: 6px;
    padding: 4px 14px;
    text-transform: lowercase;
    cursor: pointer;
    background: #fff;
    margin-top: 3rem;
  }

  .card-pill.card-current-pill {
    background: #4d8275;
    color: #ffffff;
    cursor: initial;
  }
}

.detail-image-container {
  width: 280px;
  height: 200px;
  overflow: hidden;
  position: relative;

  .detail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.detail-certification-link {
  width: 170px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  color: $certification_button_text_color;
  background-color: $primary;
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.certification-modal-container {
  .modal-content {
    background-color: #2b2b2b;
  }

  .modal-body {
    .photo-modal-img {
      padding: 10% 0;
    }
  }

  iframe {
    margin-top: 25px;
  }
}

.certificatiion-mobile-pdf-container {
  height: 80vh;
}