@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.select {
  width: 100%;

  .form-select {
    height: 48px;
    background-color: #eeeeee;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    border: none transparent;

    .dropdown-menu {
      height: 48px;
      background-color: #eeeeee;
      border-radius: 4px;
      box-shadow: none;
      width: 100%;
      border: none transparent;
    }

    .select-option {
      height: 48px;
      background-color: #eeeeee;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
      border: none transparent;
    }

    .select-option:hover {
      height: 48px;
      background-color: #cdcdcd;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
    }

    .select-option:focus {
      height: 48px;
      background-color: #dcdcdc;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
    }
  }
}

.language-switch {
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 9998;

  @media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
    right: 10px;
  }

  .form-select {
    background-color: transparent;
    border: none transparent;
    color: #ffffff;
    font-family: Poppins;
    background: url('../../../assets/images/arrow-white.png');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;

    option {
      background-color: #2b2b2b;
      color: #ffffff;
    }
  }
}

@media (max-height: 568px) and (max-width: 360px) {
  .language-switch {
    top: 10px;
  }
}