@import '../../../assets/styles/variables';
@import '../../../config/variables.scss';

.routes-service-error-modal {
  z-index: 999999;
  display: flex !important;
  justify-content: center;

  .modal-content {
    height: fit-content !important;
  }

  .modal-header {
    height: 50px !important;
  }
}

.cards-container {
  width: 100%;
  height: 53%;
  bottom: 4%;
  position: absolute;
  overflow: visible;
  white-space: nowrap;
  z-index: 6;

  .card-with-image {
    display: inline-block;
    margin: 20px;
  }

  .rec.rec-item-wrapper {
    margin-left: 7%;
    overflow: unset;
  }

  .rec.rec-slider-container {
    overflow: unset;
  }

  .card-end.card-with-image {
    width: 100%;
    min-height: 214px;

    .card-body {
      padding-top: 35px;

      p.body {
        display: flex;
        justify-content: center;
      }

      h5 {
        font-family: TT Norms;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        color: #404040;
        margin-bottom: 24px;
        text-align: center;
      }
    }
  }

  .card-pill {
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 25.5px;
    font-family: Calluna Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #404040;
    margin: 6px;
    padding: 4px 14px;
    text-transform: lowercase;
    cursor: pointer;
  }

  .card-pill.card-current-pill {
    background: #4d8275;
    color: #ffffff;
    cursor: initial;
  }

  .rec.rec-carousel-wrapper {
    margin-left: -5%;
  }

  .rec.rec-arrow {
    border: none;
    background: none;
    box-shadow: none;
    position: absolute;
    z-index: 10;
    color: $mobile_map_carousel_arrows_color;
    bottom: 140px;
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
  }

  .rec.rec-arrow:focus,
  .rec.rec-arrow:hover {
    background: none;
    border: none;
    box-shadow: none;
    color: $mobile_map_carousel_arrows_color;
  }

  .rec.rec-arrow-left {
    left: -10px;
  }

  .rec.rec-arrow-right {
    right: -10px;
  }
}

.mapboxgl-marker {
  top: -29px !important;
  left: -29px !important;
}

.navbar-brand {
  position: absolute;
  left: 35px;

  img {
    width: 161px !important;
    margin-left: 15px;
  }
}

.map-template .language-switch {
  background: url('../../../assets/images/arrow-black.png');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  margin-left: 45px;
}

.route-details-last {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: -40%;
}

.route-details-sidebar {
  overflow-x: hidden;
  overflow-y: visible;
  width: 100% !important;
  height: 300px;
  max-width: 100%;
  position: fixed;
  max-height: 300px;
  bottom: 0;
  margin-bottom: 12px;
  padding: 25px;
  z-index: 9998;

  p.body p {
    font-size: 16px;
    line-height: 20px !important;
    color: #ffffff;
  }

  h5 {
    margin-top: 1.5em;
  }

  .detail-recolections {
    display: flex;
    flex-direction: column;
    margin-bottom: 0em;
  }

  .detail-awscheck {
    margin-top: 1em;
  }

  >div:last-of-type {
    // min-height: 100%;
  }
}

.karun-seemore-link {
  color: $primary;
  text-transform: uppercase;

  &:hover {
    color: $primary
  }
}

@media (max-width: 1080px) {
  .mapboxgl-marker {
    top: -10px !important;
    left: -15px !important;
  }
}

// Modificaciones para nueva barra inferior
@media (min-width: 768px) {
  .navbar-brand img {
    z-index: 9998;
    position: absolute;
    margin-top: 19px;
  }

  .route-details-sidebar {
    width: calc(100% - 280px);
    border-top: 1px solid black;
  }

  .rec.rec-slider-container {
    overflow: visible;
  }

  .rec.rec-slider-container {
    width: 277px;
    height: 183px;
  }

  .rec.rec-carousel {
    width: fit-content;
  }

  .rec.rec-carousel-wrapper {
    width: fit-content;
  }

  #timeline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    width: 100%;
    margin-top: 18px;
    z-index: 997;
    position: relative;

    #timeline {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 4px;
      width: 394px;
      background-color: $dkt_timeline_bg;
      border-top: 1px solid $dkt_timeline_border;
      border-bottom: 1px solid $dkt_timeline_border;
      margin: 0 auto 20px auto;
      position: fixed;
      z-index: 9999;

      button {
        width: 30px;
        height: 30px;
        border: none !important;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }
  }

  #timeline-back {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    font-weight: bold;
    width: 30px;
    height: 19px;
    margin-right: 455px;
    margin-top: -20px;
    background: url('../../../assets/images/timeline-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
    position: fixed;
    filter: $dkt_timeline_arrows_filter_color;
  }

  #timeline-forward {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    font-weight: bold;
    width: 30px;
    height: 19px;
    margin-left: 455px;
    margin-top: -20px;
    background: url('../../../assets/images/timeline-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    position: fixed;
    filter: $dkt_timeline_arrows_filter_color;
  }

  #timeline-forward:not(:disabled):hover,
  #timeline-back:not(:disabled):hover {
    transition: 0.6s;
    color: white !important;
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -ms-transition: 0.6s;
    -o-transition: 0.6s;
  }

  .timeline-arrow-cards {
    border: none;
    color: black !important;
    font-weight: bold;
  }

  .timeline-arrow-disabled {
    border: none;
    filter: $dkt_timeline_inactive_arrow_filter_color !important;
    align-items: flex-end;
  }

  #timeline>button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-zero-overlap {
    position: fixed;
    z-index: 998;
    width: 200px;
    bottom: 265px;
    left: 110px;
    -webkit-filter: drop-shadow(3px 3px 3px #4b4b4b);
    filter: drop-shadow(3px 3px 3px #4b4b4b);
  }

  .active-route-button {
    background-color: $primary;
    color: $dkt_timeline_active_text_color;
    font-family: Poppins;
    font-weight: 500;
  }

  .inactive-route-button {
    background-color: $secondary;
    color: $dkt_timeline_inactive_text_color;
    font-family: Poppins;
    font-weight: 500;
  }

  .mapboxgl-ctrl {
    display: block;
  }

  #navigationControls {
    position: fixed;
    width: 100%;
    height: 360px;
    bottom: 60px;
  }

  #navigationControls div {
    position: relative !important;
    width: 30px;
    margin: 0 10px 0 auto;

    @media (min-width: 1440px) {
      margin-right: 25px !important;
    }
  }

  .modal-backdrop.show {
    z-index: 90001;
  }

  .desktop-media-modal.show {
    z-index: 90001;
  }
}

// Ipad
@media (min-width: 768px) and (min-height: 868px) and (max-width: 1365px) {
  div.route-details-sidebar {
    overflow-y: visible !important;
  }

  div#co2-container {
    margin-right: 35px;
  }
}

// Viewport intermedio desktop
@media (min-width: 768px) and (max-width: 1540px) {
  .route-details-sidebar {
    overflow: hidden !important;
  }

  .card-lens-image-desktop {
    display: none !important;
  }

  .card-lens-trace {
    flex-direction: row !important;
  }

  .card-lens-master-desktop {
    margin-bottom: 0px !important;
  }

  #timeline-container-bg {
    height: 50px;
    width: 100%;
    background-color: $card_bg;
    z-index: 996;
    position: fixed;
    margin-top: -30px;
    left: 0;
  }

  #timeline-container {
    margin-top: 13px;
  }

  //- non zero cards test
  .non-zero-routes {
    width: 100% !important;
  }

  #card-container-desktop {
    flex-direction: row !important;
  }

  .route-body-detail {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .co2-container-non-zero-card {
    margin-top: 8em !important;
  }
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) and (min-height: 568px) and (min-width: 360px) {
  .map-template {
    background-color: #000000;
    height: 95vh;
  }

  .language-switch {
    position: relative;
    margin-right: 2%;
  }

  .navbar-brand {
    position: relative;
    left: 15px;
    margin-right: 0px;

    img {
      // width: 100% !important;
      margin: 0;
    }
  }
}

@media (max-width: 360px) {
  .map-template {
    height: 100vh;
  }

  .cards-container {
    height: 62%;
    bottom: 0;
  }

  .lens-image-container {
    top: 30px !important;
  }

  .navbar-brand {
    width: 30% !important;
    margin-right: 18%;

    img {
      width: 75% !important;
      margin: 0;
    }
  }

  .language-switch {
    margin-right: 8%;
  }

  .rec.rec-arrow {
    bottom: 115px !important;
  }
}

@media (max-height: 720px) and (max-width: 540px) and (min-width: 361px) {
  .map-template {
    height: 100vh;
  }

  .cards-container {
    height: 48%;
    bottom: 20%;

    .rec.rec-arrow {
      bottom: 40px;
    }
  }

  .navbar-brand {
    width: 30% !important;
    // margin-right: 18%;

    img {
      width: 75% !important;
      margin: 0;
    }
  }

  .language-switch {
    left: -30px;
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (max-width: 768px) and (orientation: portrait) {
  .navbar-brand {
    width: 30% !important;
    z-index: 5;

    img {
      width: 75% !important;
      margin: 0;
    }
  }

  #navigationControls {
    display: none;
  }
}