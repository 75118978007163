@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';
@import '../../../config/variables.scss';

@media screen and (min-width: 768px) {
  .card-route-title-container {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    position: fixed;
    top: 90px;
    width: 100%;
    margin: 0px !important;
  }

  .card-title {
    display: flex;
    margin: 0px !important;
    font-family: Poppins !important;
    font-size: 30px !important;
    font-weight: bold;
    margin-left: 20px !important;
  }

  .card-title-index {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    background-color: #70e6f0;
    font-size: 28px;
    font-weight: bold;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
}

.card-with-image-container {
  display: flex;
  flex-direction: column;
}

.card-with-image {
  max-width: 85%;
  width: 100%;
  background-color: $card_bg;
  border: 2px solid $card_border;
  margin-top: 0 !important;

  .card-with-image-line {
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $card_button_text;
    background-color: $card_button_bg;
    padding: 0.5em 2.5em;
    width: fit-content;
    justify-self: center;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 8%;
    margin-bottom: 2%;
  }

  .card-image {
    position: absolute;
    top: -14px;
    left: 20px;
  }

  .card-body {
    text-align: center;
    padding-left: 20px;
    font-family: 'Calluna Sans';
    font-size: 16px;
    display: grid;

    .card-title-container {
      display: flex;
      text-align: left;
      align-items: center;
      min-height: 44px;
      font-family: Poppins;
      margin-bottom: 2%;

      span {
        background-color: $primary;
        color: $map_active_pin_number_color;
        font-weight: bold;
        padding: 4px 12px;
        border-radius: 100%;
        margin-right: 3%;
        font-size: 18px;
        font-weight: bold;
        height: min-content;
        width: min-content;
      }

      h2 {
        color: $text;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
    }

    h5 {
      color: $greyTitle;
      font-family: 'TT Norms';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      white-space: break-spaces;
      margin-top: 10px;
    }

    p {
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: break-spaces;
    }
  }
}

.card-iconic-image {
  width: 50%;
  display: flex;
  align-self: center;
  position: relative;
  top: 55px;
  z-index: 5;

  .iconic-image-container {
    display: flex;
    position: relative;
    top: -5px;
    width: 100%;
    min-width: 195px;
    min-height: 98px;
    align-self: center;
    margin-bottom: 1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.carousel-container {
  overflow: hidden;
  height: 160px;
}

.card-iframe-video {
  border: 0;
}

.video-container {
  width: 272px;
  height: 200px;
  overflow: hidden;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-carousel-image-container {
  width: 272px;
  height: 200px;
  overflow: hidden;
  position: relative;

  .icon-open-img {
    position: absolute;
    z-index: 3;
    top: 8px;
    right: 8px;
    width: 24px;
  }

  .card-carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modal-carousel-container {
  background-color: $modal_bg;
  border: 2px solid $modal_border;
  padding: 0;

  .modal-card-carousel-image {
    width: 250px;
  }

  .rec.rec-carousel-wrapper {
    .rec.rec-arrow {
      display: flex;
      justify-content: center;
      position: relative;
      top: 395px;
      background: $mamushka_button_bg;
      border: 2px solid $mamushka_button_border;
      box-shadow: none;
      color: $mamushka_button_arrow;
      display: flex;
      z-index: 100;
    }

    .rec.rec-arrow:active {
      background: $grey;
    }

    .rec.rec-arrow-up {
      left: 100px;
      line-height: 42px !important;
    }

    .rec.rec-arrow-down {
      right: 100px;
      line-height: 42px !important;
    }
  }
}

.modal-dialog {
  margin: 0 1.5rem;
}

.rec.rec-slider-container {
  margin: 0;
}

.modal-content {
  height: 800px;
}

.btn-close {
  color: $close_x !important;
  opacity: 1 !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
  position: absolute;
  right: 0;
}

.modal-backdrop {
  opacity: 0.9 !important;
  background-color: $grey;
}

.photo-modal-img {
  width: 100%;
}

.photo-modal-container {
  .btn-close {
    position: absolute;
    top: 0;
    filter: $close_filter;
    -webkit-filter: $close_filter;
  }

  .modal-content {
    background-color: $modal_bg;
    border: 2px solid $modal_border;
    height: 100%;

    .modal-body {
      .photo-modal-img {
        padding: 10% 0;
      }
    }
  }
}

@media (max-height: 568px) and (max-width: 360px) {
  .card-with-image {
    .card-body {
      padding-bottom: 0;
    }

    .card-title-container {
      min-height: min-content !important;

      h2 {
        font-size: 13px !important;
      }

      span {
        font-size: 13px !important;
      }
    }

    .card-with-image-line {
      margin-top: 4%;
      margin-bottom: 4%;
    }
  }

  .carousel-container {
    height: 130px;
  }

  .modal-carousel-container {
    .modal-card-carousel-image {
      max-height: 200px;
    }

    .rec.rec-carousel-wrapper {
      .rec.rec-arrow {
        top: 260px;
      }
    }
  }

  .modal-content {
    height: 540px;
  }

  .photo-modal-contaiber {
    .modal-content {
      height: 95vh;
    }
  }
}

@media (max-height: 720px) and (max-width: 540px) and (min-width: 361px) {
  .modal-content {
    max-height: 97vh;
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (orientation: portrait) {
  .iconic-image-container {
    width: 75% !important;
    top: 10px !important;
  }

  .route-details {
    .video-container {
      width: 100%;
    }
  }
}