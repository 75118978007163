@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.select {
  width: 100%;

  .form-select {
    height: 54px;
    background-color: #000000;
    border-radius: 4px;
    box-shadow: none;
    width: 100%;
    border: 2px solid white;


    .dropdown-menu {
      height: 48px;
      background-color: #EEEEEE;
      border-radius: 4px;
      box-shadow: none;
      width: 100%;
      border: none transparent;

    }

    .select-option {
      height: 48px;
      background-color: #EEEEEE;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
      border: none transparent;
    }

    .select-option:hover {
      height: 48px;
      background-color: #CDCDCD;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
    }

    .select-option:focus {
      height: 48px;
      background-color: #DCDCDC;
      border-radius: 4px;
      box-shadow: none;
      border: none;
      width: 100%;
    }

  }

  @media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
    .select-label {
      font-size: 25px !important;
      line-height: 28px !important;
    }
  }
}