@import '../../../config/variables.scss';

.location-pin {
  border-radius: 50%;
  background-color: $secondary;
  border-color: $secondary;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 2s, border-color 2s, transform 2s;

  .icon {
    width: 42px;
    height: 42px;
    stroke: white;
    fill: white;
    transition: stroke 2s, fill 2s;
  }
}

.location-pin-numbers {
  color: $white;
  font-family: Poppins;
  font-weight: bold;
  position: absolute;
  font-size: 28px;
  transition: color 2s;
}

.location-pin-mobile {
  @extend .location-pin;
  width: 34px;
  height: 34px;

  .icon {
    transform: scale(0.9);
  }

  .location-pin-numbers {
    font-size: 22px;
  }
}