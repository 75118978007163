@import '../../../config/variables.scss';

p.route-body-detail {
  width: 70%;
}

.route-body-detail h2 {
  font-family: TT Norms;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: $text;
}

.route-details-sidebar h5 {
  font-family: TT Norms;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 8px;
  margin-right: 10px !important;
  padding-top: 2px;
}


.card-iframe-video {
  border: 0
}

.route-body-detail>div>p {
  font-family: 'TT Norms';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: $text;
  width: 100%;
  margin-bottom: 0 !important;
}

.route-body-detail .detail-box {
  max-width: 173px;

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 12px;
  }
}

.route-body-detail {
  margin-top: 1em;

  .card-pill {
    border: 2px solid #404040;
    box-sizing: border-box;
    border-radius: 25.5px;
    font-family: TT Norms;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #404040;
    margin: 6px;
    padding: 4px 14px;
    text-transform: lowercase;
    cursor: pointer;
    background: #fff;
  }

  .card-pill.card-current-pill {
    background: #4d8275;
    color: #ffffff;
    cursor: initial;
  }
}

#card-container-desktop {
  display: flex;
  flex-direction: column;
}

.non-zero-routes {
  display: none;
}

.detail-image-desktop-expanded {
  width: 100%;
  height: 85vh;
}

.detail-video-desktop-expanded {
  height: 85vh;
}

.route-body-certifications {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.route-body-boxes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.route-body-box {
  width: 100%;
  color: white;
  padding-left: 12px;
  padding-top: 10px;

  h3 {
    font-family: TT Norms;
    font-size: 30px;
    margin-bottom: 0 !important;
  }

  p {
    font-family: TT Norms;
    font-size: 20px;
    max-width: 229px;
    color: white !important;
    margin-top: 0 !important;

  }
}

.box1 {
  background-color: #4A5C6B;
  margin-bottom: 11px;
}

.box2 {
  background-color: #C9984F;
}

@media (min-width: 768px) and (max-width: 1540px) {

  .certificaciones {
    padding-left: 25px;
  }

  .route-body-box {
    display: none;
  }
}

@media (min-width: 768px) {
  .route-details {
    width: calc(100% - 350px);
    height: 220px;
  }

  .route-body-content {
    padding-right: 24px;
    padding-left: 25px;
    width: 590px;
  }

  .route-body-detail h2 {
    margin-top: 0px;
  }

  .rec-arrow-left {
    display: flex;
    justify-content: flex-end;
  }

  #card-container-desktop {
    margin-top: 20px;
    height: 222px;
  }

  .rec .rec-swipable {
    display: flex;
    flex-direction: row;
    height: 215px;

    div>div {
      // width: 300px;
      height: 100%;
      // padding: 0px;
      align-items: center;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .modal-dialog {
    margin: 0 !important;
  }

  .desktop-media-modal {
    display: flex !important;
    align-items: center !important;
    margin: 0 0.5rem;


    .modal-content {
      background-color: $modal_bg;
      height: fit-content;
      width: max-content;
    }

    .modal-header {
      background-color: $modal_bg;

      h5 {
        color: $text;
        font-size: 1.25rem;
        margin: 0;
        font-weight: 400;
        font-family: TT Norms;
      }

    }

    object {
      width: 95vw;
      height: 90vh;
    }

    button {
      color: $close_x !important;
      filter: $close_filter;
      -webkit-filter: $close_filter;
      position: absolute;
      top: 0;
    }

    img {
      width: 100vh;
    }

    .detail-certification-desktop {
      width: 100%;
      height: 100%;
    }
  }

  .route-body-detail .detail-box h3.detail-box-amount {
    font-family: TT Norms;
    font-size: 24px;
    font-weight: bold;
    color: #70e6f0;
    margin-right: 8px;
  }

  .route-body-detail .detail-box h4.detail-box-infodetail {
    font-size: 18px !important;
    font-weight: normal;
    color: #ffffff;
    margin-left: 8px;
  }

  @media (max-width: 980px) {
    .route-details {
      width: calc(100%);
      height: 220px;
    }

    .rec.rec-carousel {
      width: 100%;
      margin-top: 1em;
    }
  }
}

@media (min-width: 1152px) {
  .download-certifications {
    width: 454px !important;
    height: 56px;

    p {
      font-family: TT Norms;
      font-weight: 500;
      font-size: 20px;
      color: white;
      text-transform: uppercase;
      margin: 0;
    }
  }

  #card-container-desktop {
    display: flex;
    flex-direction: row;
    width: 98%;
  }

  .route-body-detail {
    margin-top: 0em;
    display: flex;
    width: 72%;

    .route-body-certifications {
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .route-body-boxes-container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      margin-top: 0;
    }

    .route-body-box {
      width: 220px;
      // height: 136px;
      color: white;
      padding-left: 20px;
      padding-top: 24px;

      h3 {
        font-family: TT Norms;
        font-size: 26px;
      }

      p {
        font-family: TT Norms;
        font-size: 16px;
        max-width: 192px;
      }
    }

    .box1 {
      background-color: #4A5C6B;
      margin-bottom: 0px;
      height: 120px;
    }

    .box2 {
      background-color: #C9984F;
      height: 120px;
    }
  }
}


.boton-certificados {
  width: 235px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #70e6f0;
  line-height: 22px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.boton-certificados {
  width: 235px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #70e6f0;
  line-height: 22px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.download-certifications {
  background-color: #7C3A2D;
  border: none;
  margin-top: 16px;
  width: 100%;

  p {
    font-family: TT Norms;
    font-weight: 500;
    font-size: 16px;
    color: white !important;
    text-transform: uppercase;
  }
}


.certificaciones {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.detail-image-desktop {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}