html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
  background-color: #000000;
}

#root {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

main {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

main>div {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

@media (orientation: landscape) and (max-height: 767px) and (max-width: 950px) {
  body {
    display: none;
  }
}