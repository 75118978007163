footer {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.first-line {
  height: 12px;
  width: 464px;
}

.second-line {
  height: 12px;
  width: 269px;
}

.third-line {
  height: 12px;
  width: 839px;
}

.fourth-line {
  height: 12px;
  width: 345px;
}