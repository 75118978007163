//Colors
$primary: #3E8279;
$secondary: #C9984F;
$black: #2B2B2B;
$white: #F2F2F2;
$error: #fb2424;
$transparent: transparent;

$text: $black;
$close_x: $black;
$close_filter: none; //en botines = invert(1)

$home_form_bg: transparent;
$home_button_text: $white;
$home_button_border: $primary;
$home_placeholder_color: $white;

$modal_bg: $white;
$modal_border: $white;

$auditlog_border: $secondary;
$auditlog_accordion_button_filter: none; // botines = invert(1) grayscale(100%) brightness(200%);

$card_bg: $white;
$card_border: $white;
$card_number: $white;
$card_title: $white;
$card_button_text: $white;
$card_button_bg: $primary;

$search_select_background: $transparent;
$search_action_button_color: $white;

$map_active_pin_number_color: $white;

$mobile_map_carousel_arrows_color: $white;

$certification_button_text_color: $white;

$dkt_timeline_arrows_filter_color: invert(0.9);
$dkt_timeline_inactive_arrow_filter_color: invert(0.5);
$dkt_timeline_active_text_color: $white;
$dkt_timeline_inactive_text_color: $white;
$dkt_timeline_bg: $black;
$dkt_timeline_border: $white;
$dkt_card_title_color: $black;
$dkt_map_carousel_arrows_color: $black;
$dkt_vertical_line_color: $black;
$dkt_cardzero_blockchain_img_color_filter: none;

$mamushka_button_bg: $primary;
$mamushka_button_arrow: $white;
$mamushka_button_border: $primary;


$footprint_border: $primary;
$footprint_title: $white;
$footprint_title_bg: $primary;


$tooltip_bg : #2B2B2B;
$tooltip_border: #707070;
$tooltip_text: $white;
$tooltip_move_button: $secondary;


//to use in .js archives
:export {
    primary: $primary;
    map_active_pin_number_color: $map_active_pin_number_color;

}