//LENS
@font-face {
  font-family: TT Norms Pro Karun Regular;
  src: url('../fonts/lens/TT-Norms-Pro-Karun-Regular.otf');
}

@font-face {
  font-family: TT Norms Pro Karun Normal;
  src: url('../fonts/lens/TT-Norms-Pro-Karun-Normal.otf');
}

@font-face {
  font-family: TT Norms Pro Karun Medium;
  src: url('../fonts/lens/TT-Norms-Pro-Karun-Medium.otf');
}

@font-face {
  font-family: TT Norms Pro Karun SemiBold;
  src: url('../fonts/lens/TT-Norms-Pro-Karun-SemiBold.otf');
}

@font-face {
  font-family: TT Norms Pro Karun Bold;
  src: url('../fonts/lens/TT-Norms-Pro-Karun-Bold.otf');
}

//BOOT
@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: MyriadPro;
  src: url('../fonts/MyriadPro-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: MyriadPro;
  src: url('../fonts/MyriadPro-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: MyriadPro;
  src: url('../fonts/MyriadPro-Cond.otf');
  font-weight: 300;
}

@font-face {
  font-family: MyriadPro;
  src: url('../fonts/MyriadPro-Semibold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSansLight.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSansRegular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSansSemiBold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Calluna Sans';
  src: url('../fonts/CallunaSansBold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'TT Norms';
  src: url('../fonts/TT Norms Pro Karun Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'TT Norms';
  src: url('../fonts/TT Norms Pro Karun Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'TT Norms';
  src: url('../fonts/TT Norms Pro Karun Bold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'TT Norms';
  src: url('../fonts/TT Norms Pro Karun Black.otf');
  font-weight: 700;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url('../fonts/SpaceGrotesk-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url('../fonts/SpaceGrotesk-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url('../fonts/SpaceGrotesk-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url('../fonts/SpaceGrotesk-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: SpaceGrotesk;
  src: url('../fonts/SpaceGrotesk-Bold.ttf');
  font-weight: 700;
}