@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.aws-check {
    img {
        width: 13px;
        margin-right: 5px;
    }

    font-family: TT Norms;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}