@import '../../../assets/styles/variables';
@import '../../../config/variables.scss';

.tooltip-fade-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  cursor: auto;

  h1 {
    color: white;
  }
}

.opaque {
  opacity: 0.3;
}

@media only screen and (min-width: 769px) and (min-device-width: 1024px) {
  #tooltip-topbar {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 40px;
    font-family: Poppins;
    font-size: 17px;
    background-color: $primary;

    p {
      margin: 0 5px 0 0;
      padding: 0;
      color: $tooltip_text;
    }

    button {
      width: 167px;
      height: 23px;
      margin: 0 8px 0 0;
      padding: 2px 1px;
      border: none;
      color: $white;
      background-color: $black;
      font-family: TT Norms;
      font-size: 14px;
      font-weight: bold;
    }
  }

  #boton-tooltip {
    position: fixed;
    top: 23px;
    right: 127px;
    width: 30px;
    height: 30px;

    button {
      background: $primary;
      color: $tooltip_text;
      font-weight: bold;
      z-index: 999;
      width: 30px;
      height: 30px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
    }

    button:hover {
      background: $white;
      color: $text;
    }

    .highlighted {
      box-shadow: 0 0 0 5px $primary;
      animation: shadowPulse 1.5s infinite alternate;
      -webkit-animation: shadowPulse 1.5s infinite alternate;
    }
  }

  @keyframes shadowPulse {
    0% {
      box-shadow: 0 0 0 1px $primary;
    }

    100% {
      box-shadow: 0 0 0 10px $primary;
    }
  }

  .toolkit-highlighted-component {
    z-index: 99999 !important;
    position: relative;
  }

  .toolkit-tip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 8px 4px 8px;
    width: 344px;
    height: 105px;
    background-color: $tooltip_bg;
    z-index: 99999;
    border: 2px solid $tooltip_border;
    border-radius: 6px;
    box-shadow: inset 1px 1px 9px $tooltip_border;

    p {
      color: $tooltip_text;
      font-size: 14px;
      margin-bottom: 0px;
      padding: 11px;
      text-align: center;
    }

    button.tooltip-close {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3px;
      right: 3px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      background-color: transparent;
      color: $white;
    }

    div.tooltip-nav {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        height: 20px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
      }

      .prevTooltip {
        align-items: flex-start;
        color: $tooltip_move_button;
        width: fit-content;
        padding: 3px;
      }

      .nextTooltip {
        align-items: flex-end;
        color: $tooltip_move_button;
        width: fit-content;
        padding: 3px;
        margin-left: auto;
      }
    }
  }

  #toolkit-tip-1 {
    position: fixed;
    bottom: 310px;
    margin-left: 180px;
  }

  #toolkit-tip-2 {
    position: fixed;
    bottom: 290px;
  }

  #toolkit-tip-3 {
    position: fixed;
    margin-bottom: 235px;
    margin-right: 30px;
  }

  .toolkit-tip::before {
    content: '';
    position: absolute;
    display: block;
    bottom: -23px;
    right: 10%;
    border: 12px solid transparent;
    border-bottom-color: $tooltip_border;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .toolkit-tip::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -24px;
    right: 9%;
    border: 15px solid transparent;
    border-bottom-color: $tooltip_bg;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) and (min-device-width: 1024px) {
  .certificaciones-tooltip {
    position: fixed;
    bottom: 10px;
    left: 40%;
  }
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
  .tooltip-fade-bg {
    z-index: 999;
  }

  #tooltip-topbar {
    z-index: 1200;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 40px;
    font-family: Poppins;
    font-size: 1rem;
    background-color: $primary;

    p {
      margin-top: 15px;
      color: $tooltip_text;
    }

    button {
      display: none;
    }
  }

  .toolkit-tip {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 8px 4px 8px;
    width: 340px;
    height: 105px;
    background-color: $tooltip_bg;
    z-index: 9999;
    border: 2px solid $tooltip_border;
    border-radius: 9px;
    box-shadow: inset 1px 1px 9px $tooltip_border;

    p {
      color: $tooltip_text;
      font-size: 14px;
      margin-bottom: 0px;
      padding: 11px;
      white-space: initial;
      text-align: center;
    }

    button.tooltip-close {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3px;
      right: 3px;
      border: none;
      font-size: 16px;
      font-weight: bold;
      background-color: transparent;
      color: $white;
    }

    div.tooltip-nav {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      button {
        height: 20px;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
      }

      .prevTooltip {
        align-items: flex-start;
        color: $tooltip_move_button;
        width: fit-content;
        padding: 3px;
      }

      .nextTooltip {
        align-items: flex-end;
        color: $tooltip_move_button;
        width: fit-content;
        padding: 3px;
        margin-left: auto;
      }
    }
  }

  .toolkit-tip::before {
    content: '';
    position: absolute;
    display: block;
    bottom: -25px;
    right: 10%;
    border: 12px solid transparent;
    border-bottom-color: $tooltip_border;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .toolkit-tip::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -25px;
    right: 9.2%;
    border: 15px solid transparent;
    border-bottom-color: $tooltip_bg;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  #toolkit-tip-1 {
    position: fixed;
    top: 57%;
    left: 50%;
    transform: translateX(-50%);
  }

  #toolkit-tip-2 {
    position: fixed;
    top: 33%;
    left: 50%;
    transform: translateX(-50%);
  }

  #toolkit-tip-3 {
    width: 310px;
    height: 120px;
    position: fixed;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
    color: $tooltip_text !important;

    p {
      color: $tooltip_text !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0 !important;
    }
  }

  #boton-tooltip {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 65px;
    left: 89%;

    button {
      background: $primary;
      color: $tooltip_text;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      z-index: 9999;
      width: 30px;
      height: 30px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
    }

    button:hover {
      background: $white;
    }

    .highlighted {
      box-shadow: 0 0 0 5px $primary;
      animation: shadowPulse 1.5s infinite alternate;
      -webkit-animation: shadowPulse 1.5s infinite alternate;
    }
  }

  @keyframes shadowPulse {
    0% {
      box-shadow: 0 0 0 1px $primary;
    }

    100% {
      box-shadow: 0 0 0 10px $primary;
    }
  }

  .toolkit-highlighted-component {
    z-index: 999 !important;
    position: relative;
  }
}

@media (max-height: 666px) and (orientation: portrait) {
  // #boton-tooltip {
  //   top: 300px;
  // }

  #toolkit-tip-1 {
    top: 45%;
  }

  #toolkit-tip-2 {
    top: 25%;
  }

  #toolkit-tip-3 {
    bottom: 140px;
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (max-width: 768px) and (orientation: portrait) {
  #toolkit-tip-3 {
    bottom: 170px;
  }
}