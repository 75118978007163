//Colors
$white: #ffffff;
$black: #000000;

$grey: #404040;
$darkGrey: #2b2b2b;
$lighterGrey: #f2f2f2;
$lightGrey: #c4c4c4;

$green: #4d8275;
$lightGreen: #829f91;

$greyTitle: #444444;
$brownTitle: #c29a5b;
$darkBrownTitle: #7e341b;
$ligthBrownTitle: #fccf78;

//Breakpoint;
$mobile-width-max: 767px;
$mobile-height-max: 1000px;

$desktop-width-min: 768px;
$desktop-width: 1440px;
$desktop-height: 900px;
