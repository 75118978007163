@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.sidebar-container {
  background: #2b2b2b;
  z-index: 1000;

  .navbar {
    padding: 0;
    opacity: 1;

    .container-fluid {
      justify-content: center;
    }
  }

  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border: none;
    position: absolute;
    left: 10px;
  }
}

#offcanvasNavbar {
  background-color: #fff;
  background-size: contain;
  background-position: top;

  .items-lens {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 10px;

    .items-lens-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: -25px;
      padding: 1rem 10px;
    }

    .item-description {
      height: 75%;

      .detail-box {
        flex: 1;
        padding: 14px 16px;
        border-radius: 10px;
        background: #4d8275;
        margin-right: 10px;
        width: 200px;

        h3 {
          font-family: Calluna Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 43px;
          letter-spacing: 0.02em;
          margin-bottom: 10px;
          color: #ffffff;
        }

        h4 {
          font-family: Calluna Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.02em;
          margin-top: 8px;
          color: #ffffff;

          opacity: 0.8;
        }
      }
    }

    .item-image {
      display: flex;
      align-content: center;
      justify-content: center;

      img {
        width: 150px;
        margin-top: -15px;
        margin-bottom: -35px;
      }
    }

    h2 {
      font-family: TT Norms;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #4d8275;
    }

    h3 {
      font-family: Calluna Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;

      color: #f2f2f2;
      margin-bottom: 28px;
    }

    h4 {
      font-family: Calluna Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
      margin-top: 24px;
      margin-bottom: 8px;

      img {
        margin-left: 16px;
      }
    }

    p {
      font-family: Calluna Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
      font-weight: normal;
    }
  }
}

@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
  #offcanvasNavbar {
    .items-lens {
      margin-top: 0;
    }
  }

  .sidebar-container {
    z-index: unset;
    width: 100%;
    position: absolute;
    padding-top: 10px;
    background-color: transparent;

    .navbar {
      .container-fluid {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0;
        align-items: flex-start;
      }

      .navbar-brand-mobile {
        margin-left: 2%;
        margin-top: 2%;
      }

      .traceNav {
        display: flex;
        justify-content: flex-end;

        .navbar-brand {
          left: 0;
          width: 35%;
          margin-left: 1em;
        }

        .btn-close.btn-close-white {
          z-index: 3;
          top: 12px;
          right: 12px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .sidebar-container {
    .traceNav {
      .btn-close.btn-close-white {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-height: 850px) and (min-width: 541px) and (max-width: 768px) and (orientation: portrait) {
  .sidebar-container {
    .navbar {
      .container-fluid {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0;
        align-items: flex-start;
      }

      .navbar-brand-mobile {
        margin-left: 2%;
        margin-top: 2%;
      }

      .traceNav {
        .language-switch {
          left: 10px;
          top: 10px;
        }

        .navbar-brand {
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          width: 35% !important;
          margin: 0;

          img {
            width: 100% !important;
          }
        }

        .btn-close.btn-close-white {
          z-index: 3;
          top: 12px;
          right: 12px;
        }
      }
    }

    .language-switch {
      .form-select {
        font-size: 1.5rem;
      }
    }
  }
}