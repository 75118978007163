@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';
@import '../../../config/variables';

@media (min-width: 768px) {
  .co2-container {
    display: flex;
    position: absolute;
    top: 15px;
    right: 70px;
    width: 300px;
    height: 241px;
    min-height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 12px; //vwport 1440

    h4 {
      font-family: TT Norms;
      font-size: 25px;
      color: $footprint_title;
      background-color: $footprint_title_bg;
      width: 100%;
      padding: 5px 19px;
    }

    .info-carbon-footprint {
      border: 1px solid $text;
      display: inline-block;
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;

      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }



    p {
      color: $text;
    }
  }

  .desktop-info-footprint-modal {
    z-index: 90003;
    color: #fff;
    display: flex !important;
    align-items: center !important;

    .modal-content {
      background-color: $modal_bg;
      height: fit-content;
      width: max-content;
      color: $text;
    }

    .modal-header {
      background-color: $modal_bg;

      h5 {
        color: $text;
        font-size: 1.25rem;
        margin: 0;
        font-weight: 400;
        font-family: TT Norms;
      }
    }

    button {
      color: $close_x;
      filter: $close_filter;
      -webkit-filter: $close_filter;
      position: absolute;
      top: 0;
    }


  }


  .co2-inner-container {
    border: 1px solid $footprint_border;
  }

  .huella-carbon-titulos {
    margin-block-end: 0 !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    font-size: 20px !important;
    margin-top: 3px !important;
  }

  .carbon-data-container {
    display: flex;
    width: 130px;

    h5 {
      font-family: TT Norms;
      font-weight: 400;
      font-size: 20px;
      display: flex;
      margin: 0;
      align-items: center;
      color: $text;
    }
  }

  @media (max-width: 840px) {
    // #co2-container {
    //   margin-top: 2em;
    // }

    #co2-info,
    #co2-info2,
    #co2-info3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2px 0;
    }

    .carbon-data-container {
      display: flex;
      justify-content: center;
      width: 130px;
    }
  }

  #co2-info,
  #co2-info2,
  #co2-info3 {
    display: flex;
    margin: 2px 0;
    border-bottom: 1px solid $footprint_border ;

    div {
      padding-left: 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .co2-container {
    margin-top: 25px;
  }

  .co2-inner-container {
    margin-left: 18px;
  }
}