@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.action-button {
    width: 49px;
    height: 49px;
    background: black;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin: 0 0 0 20px;
    padding: 0;

}

.action-button:hover {
    background: $lighterGrey;
    color: $darkGrey;
}

.action-button:active {
    background: $black;
    color: $white;
}


.action-button:disabled {
    background: $lightGrey;
    color: $white;
}

.action-button.inverted {
    background: $green;
    font-size: 18px;
    line-height: 21px;
}

.action-button.inverted:hover {
    background: $lighterGrey;
    color: $darkGrey;
}

.action-button.inverted:active {
    background: $lightGreen;
}

.action-button.inverted:disabled {
    background: $lightGrey;
    color: $white;
}


@media (max-height: $mobile-height-max) and (max-width: $mobile-width-max) {
    .action-button {
        margin: 0;
        width: 52px;
        height: 53px;
    }
}